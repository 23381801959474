.NavbarItems {
    background: transparent;
    background-clip: content-box;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 2;
}

.HBLogo {
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0 0 5px;
    font-size: 16px;
}

.HBLogoImage {
    height: 80px;
    width: auto;
    padding: 1em;
    object-fit: contain; 
}

.HBLogoImageContainer {
display: flex;
}


.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 0px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-left: auto;
}

.nav-links {
    color: #fe5fbb;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background: #fe5fbb;
    color: #FFFF;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}

.fa-bars {
    color: #fe5fbb;
}

.menu-icon {
    display: none;
}

.impNavFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {

    .HBLogoImage {
        height: 60px;
        width: auto;
        padding: 1rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    .HBLogo {
        display: none;
    }

    .NavbarItems {
        background: #ffedb3;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        position: relative;
        /* padding-left: 3rem; */
        
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -200%;
        opacity: 1;
        transition: all 0.5s ease;
        padding-left: 0px;
        padding-top: 0px;
    }

 .nav-menu.active {
        background: #ffedb3;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        height: auto;
        margin-top: 0;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }
  
    .nav-links:hover {
        background-color: #fe5fbb;
        border-radius: 0;
    }

    .HBLogo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 1;
    } 
}

@media screen and (max-width: 644px) { 
    .impNavFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 1;
}
    .btn {
        margin-bottom: 0.2em;
    }
}

@media screen and (max-width: 520px) { 
    .impNavFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: 6em;
    overflow: hidden;
}

    .btn {
        margin-bottom: 0.2em;
    }

    .HBLogoImage {
        height: 60px;
        width: auto;
        padding: 1rem;
        position: absolute;
        top: 0;
        left: 0;
    }
}

