.FAQTitle {
    color: #f791b3;
    font-weight: 700;
    text-align: left;
}

.FAQText {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}