.findUs {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
    justify-content: "center";
    padding-top: 0em;
}

.paper {
    color: #393736;
    flex-grow: 1;
    width: auto;
    height: 58em;
    margin: 0.5em 6.5em;
}

