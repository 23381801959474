body {
   background-color: #fdf8e2;
}

.followUs {
    font-size: 32px;
    text-align: center;
    margin-top: 0.8em;
 }
.socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 .socialsIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
 }

 .socialIcon {
    margin: 0em 1em 2em 1em;
 }

 .socialsBox {
    margin-top: 10em;
    border-radius: 5px;
 }
 @media screen and (max-width: 644px) { 
   .socialsIconContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      padding-top: 2em;
   }
 }