body {
  background-color: #fdf8e2;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.menulogo.img {
  height: 10px;
  width: 10px;
}


.dashboardbackgrnd {
  background-image: url('./Pages/srcImages/backgroundDesktop.png');
  background-size: cover;
  height: 100vh;
  width: 100%;
}

@media screen and (max-width: 1024px){
  .dashboardbackgrnd {
    background-image: url('./Pages/srcImages/backgroundTablet.png');
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }
}
@media screen and (max-width: 420px){
  .dashboardbackgrnd {
    background-image: url('./Pages/srcImages/backgroundMobile.png');
    background-size: contain;
    height: 100vh;
    background-repeat: space;
  }
}




