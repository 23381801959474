:root {
    --primary: #fe5fbb;
}

.btn {
    padding: 12px 26px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;
}

.btn:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    background: #ffa2d8;
    color: #000000;
    transition: 250ms;
}

.btn--primary {
    background-color: var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 18px;
    color: white;
}

.btn--large {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20;
    color: white;
}

@media screen and (max-width: 960px) {
    .btn {
        padding: 10px 22px;
        border-radius: 4px;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 14px;
        
    }
}