body {
  background-color: white;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  background-color: #fdf8e2;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.menulogo.img {
  height: 10px;
  width: 10px;
}


.dashboardbackgrnd {
  background-image: url(/static/media/backgroundDesktop.47300845.png);
  background-size: cover;
  height: 100vh;
  width: 100%;
}

@media screen and (max-width: 1024px){
  .dashboardbackgrnd {
    background-image: url(/static/media/backgroundTablet.d5d2bb89.png);
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }
}
@media screen and (max-width: 420px){
  .dashboardbackgrnd {
    background-image: url(/static/media/backgroundMobile.8a8c7f5b.png);
    background-size: contain;
    height: 100vh;
    background-repeat: space;
  }
}





:root {
    --primary: #fe5fbb;
}

.btn {
    padding: 12px 26px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;
}

.btn:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    background: #ffa2d8;
    color: #000000;
    transition: 250ms;
}

.btn--primary {
    background-color: #fe5fbb;
    background-color: var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid #fe5fbb;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 18px;
    color: white;
}

.btn--large {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20;
    color: white;
}

@media screen and (max-width: 960px) {
    .btn {
        padding: 10px 22px;
        border-radius: 4px;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 14px;
        
    }
}
.NavbarItems {
    background: transparent;
    background-clip: content-box;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

.HBLogo {
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0 0 5px;
    font-size: 16px;
}

.HBLogoImage {
    height: 80px;
    width: auto;
    padding: 1em;
    object-fit: contain; 
}

.HBLogoImageContainer {
display: flex;
}


.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 0px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-left: auto;
}

.nav-links {
    color: #fe5fbb;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background: #fe5fbb;
    color: #FFFF;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}

.fa-bars {
    color: #fe5fbb;
}

.menu-icon {
    display: none;
}

.impNavFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {

    .HBLogoImage {
        height: 60px;
        width: auto;
        padding: 1rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    .HBLogo {
        display: none;
    }

    .NavbarItems {
        background: #ffedb3;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        position: relative;
        /* padding-left: 3rem; */
        
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -200%;
        opacity: 1;
        transition: all 0.5s ease;
        padding-left: 0px;
        padding-top: 0px;
    }

 .nav-menu.active {
        background: #ffedb3;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        height: auto;
        margin-top: 0;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }
  
    .nav-links:hover {
        background-color: #fe5fbb;
        border-radius: 0;
    }

    .HBLogo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
                transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 1;
    } 
}

@media screen and (max-width: 644px) { 
    .impNavFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 1;
}
    .btn {
        margin-bottom: 0.2em;
    }
}

@media screen and (max-width: 520px) { 
    .impNavFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: 6em;
    overflow: hidden;
}

    .btn {
        margin-bottom: 0.2em;
    }

    .HBLogoImage {
        height: 60px;
        width: auto;
        padding: 1rem;
        position: absolute;
        top: 0;
        left: 0;
    }
}


.findUs {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
    justify-content: "center";
    padding-top: 0em;
}

.paper {
    color: #393736;
    flex-grow: 1;
    width: auto;
    height: 58em;
    margin: 0.5em 6.5em;
}


body {
   background-color: #fdf8e2;
}

.followUs {
    font-size: 32px;
    text-align: center;
    margin-top: 0.8em;
 }
.socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 .socialsIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
 }

 .socialIcon {
    margin: 0em 1em 2em 1em;
 }

 .socialsBox {
    margin-top: 10em;
    border-radius: 5px;
 }
 @media screen and (max-width: 644px) { 
   .socialsIconContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      padding-top: 2em;
   }
 }
.FAQTitle {
    color: #f791b3;
    font-weight: 700;
    text-align: left;
}

.FAQText {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}
